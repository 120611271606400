export const getCurrencySymbol= (code)=>{
    const currencySymbols = {
      "ALL": "Lek",
      "AFN": "؋",
      "ARS": "$",
      "AWG": "ƒ",
      "AUD": "$",
      "AZN": "₼",
      "BSD": "$",
      "BBD": "$",
      "BYR": "p.",
      "BZD": "BZ$",
      "BMD": "$",
      "BOB": "$b",
      "BAM": "KM",
      "BWP": "P",
      "BGN": "лв",
      "BRL": "R$",
      "BND": "$",
      "KHR": "៛",
      "CAD": "$",
      "KYD": "$",
      "CLP": "$",
      "CNY": "¥",
      "COP": "$",
      "CRC": "₡",
      "HRK": "kn",
      "CUP": "₱",
      "CZK": "Kč",
      "DKK": "kr",
      "DOP": "RD$",
      "EGP": "£",
      "SVC": "$",
      "EEK": "kr",
      "EUR": "€",
      "FKP": "£",
      "FJD": "$",
      "GEL": "₾",
      "GHC": "¢",
      "GIP": "£",
      "GTQ": "Q",
      "GGP": "£",
      "GYD": "$",
      "HNL": "L",
      "HKD": "$",
      "HUF": "Ft",
      "ISK": "kr",
      "INR": "₹",
      "IDR": "Rp",
      "IRR": "﷼",
      "IMP": "£",
      "ILS": "₪",
      "JMD": "J$",
      "JPY": "¥",
      "JEP": "£",
      "KZT": "лв",
      "KPW": "₩",
      "KRW": "₩",
      "KGS": "лв",
      "LAK": "₭",
      "LVL": "Ls",
      "LBP": "£",
      "LRD": "$",
      "LTL": "Lt",
      "MKD": "ден",
      "MYR": "RM",
      "MUR": "₨",
      "MXN": "$",
      "MNT": "₮",
      "MZN": "MT",
      "NAD": "$",
      "NPR": "₨",
      "ANG": "ƒ",
      "NZD": "$",
      "NIO": "C$",
      "NGN": "₦",
      "NOK": "kr",
      "OMR": "﷼",
      "PKR": "₨",
      "PAB": "B/.",
      "PYG": "Gs",
      "PEN": "S/.",
      "PHP": "₱",
      "PLN": "zł",
      "QAR": "﷼",
      "RON": "lei",
      "RUB": "₽",
      "SHP": "£",
      "SAR": "﷼",
      "RSD": "Дин.",
      "SCR": "₨",
      "SGD": "$",
      "SBD": "$",
      "SOS": "S",
      "ZAR": "R",
      "LKR": "₨",
      "SEK": "kr",
      "CHF": "CHF",
      "SRD": "$",
      "SYP": "£",
      "TWD": "NT$",
      "THB": "฿",
      "TTD": "TT$",
      "TRL": "₺",
      "TVD": "$",
      "UAH": "₴",
      "GBP": "£",
      "USD": "$",
      "UYU": "$U",
      "UZS": "лв",
      "VEF": "Bs",
      "VND": "₫",
      "YER": "﷼",
      "ZWD": "Z$"
    };

    const icon = currencySymbols[code] || code;
    
    return icon
  }