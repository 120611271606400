import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import RegistrationForm from './components/RegistrationForm';
import AuthorizedComp from './components/Authorized';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import "/node_modules/flag-icons/css/flag-icons.min.css";
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/authorized" element={<AuthorizedComp />} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
