import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Input, Select, Modal  } from 'antd';
import moment from 'moment-timezone';
import {ErrorMsg                                                                                                                                                                                                                                                                                       } from "./ErrorMessage";
import LogoImg from "../assets/image/logoAlpide.svg";
import SuccessIcon from "../assets/image/successIcon.png";

function ForgetForm(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const [email, setEmail] = useState('');
  const [invalidEmailClass, setInvalidEmailClass] = useState(null);
  const [validPassword, setValidPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [invalidPasswordClass, setInvalidPasswordClass] = useState(null);
  const [passwordMismatch, setPasswordMismatch] = useState(null);
  const [repeatPassword, setRepeatPassword] = useState(null);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [invalidRepeatPasswordClass, setInvalidRepeatPasswordClass] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sentDone, setSentDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState(true);

  // Get the value of the 'code' parameter
   let source = urlParams.get("source");
  // const getValidURL = (source, country) => {
  //   let url;
  //   switch (source) {
  //     case 'erpApp': {
  //       url = process.env.REACT_APP_ERP_APP_URI + '/forgotpassword';
  //       break;
  //     }
  //     default: {
  //       url = process.env.REACT_APP_ERP_APP_URI + '/forgotpassword';
  //     }
  //   }
  //   return url;
  // }
  // useEffect(() => {

  //   let redirectUri = getValidURL(source);
  //   //window.location = redirectUri;
  // }, [source]);
  let repeatErrorMessage = <ErrorMsg
    message={<div className="errorMsg">Password does not match</div>}
    validator={() => {
      return !submittedOnce || (repeatPassword && !passwordMismatch);
    }}
  />
  const validateEmail = (val) => {
    return !!(val && val.toLowerCase().match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/))
  }
  if (!repeatPassword) {
    repeatErrorMessage = <ErrorMsg
      message={<div className="errorMsg">Confirm password is required</div>}
      validator={() => {
        return !submittedOnce || !!repeatPassword;
      }}
    />
  }

  
  const handleForgotPassword = async () => {
    if(isLoading){return;}
    
    if (!submittedOnce) {
        setSubmittedOnce(true);
      }


      if ( email && validateEmail(email)) {
     setIsLoading(true);
        let payload = {
          email:email
        }
        try {
          const config = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body:  JSON.stringify(payload)
          }
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_ROOT_CONTEXT}/clientRelationship/forgotPassword`, config)
         
          if (response.ok) {
            setSentDone(true)
            setIsLoading(false);
            const responseData = await response.json();
         
            return response
          } else {
            setErrorMessage(true)
            setIsLoading(false);
          }
        } catch (error) {
          
          setIsLoading(false);
        }
      } else {
       
        setPassword(password);
        setRepeatPassword(repeatPassword);
        setInvalidPasswordClass(!password || !validPassword ? 'input-text-error' : '');
        setInvalidRepeatPasswordClass((!repeatPassword || !passwordMismatch) ? 'input-text-error' : '');
        setPasswordMismatch(password !== repeatPassword);
        setInvalidEmailClass(validateEmail(email) ? '' : 'input-text-error');

      }
  }

  return (
    <div className="forgetPasswordPart">
      <div className="forgetPasswordForm">
       {!sentDone ? 
       <div >
          <div><img src={LogoImg} /></div>
          <div>
            <h2>Password Reset</h2>
            <p>
              Enter your email address that you used to register your account.
              We will send you an email with your username and password
            </p>
          </div>
            <Form>
              <div className="inputField">
                          <Input
                            placeholder="Enter Registered Email-id"
                            type='text'
                            maxLength={254}
                            value={email}
                            className={invalidEmailClass || 'signup-form-input'}
                            onChange={(e) => {
                              setEmail(e.target.value)
                              setErrorMessage(false)
                              setInvalidEmailClass(validateEmail(e.target.value) ? '' : 'input-text-error')

                            }}
                            validator={(val) => {
                              return validateEmail(val);
                            }}
                            autoComplete="none"
                          />
                          <ErrorMsg
                            validator={() => {
                              return !submittedOnce || !!email;
                            }}
                            message={<div className="errorMsg">Registered email-id is required</div>}
                          />
                           <ErrorMsg
                            validator={() => {
                              return !submittedOnce || (email ? validateEmail(email): true);
                            }}
                            message={<div className="errorMsg">Invalid email address </div>}
                          />
                          <ErrorMsg
                            validator={() => {
                              return !submittedOnce || !errorMessage;
                            }}
                            message={<div className="errorMsg">Email account does not exist</div>}
                          />
              </div>
              <div className="inputField">
                <Button className="signup-create-account"  onClick={() => {
                            handleForgotPassword(props);
                          }}>
                    {!isLoading ? <span>  Send Email</span>
                    :
                      <span><i class="fa fa-circle-o-notch fa-spin"></i>  Sending</span>
                    }
                </Button>
              </div>
            </Form>
          <div className="inputField text-center">
            Back to <span className='cursor-pointer link' onClick={() => {
              window.location = window.location.origin+"/?source="+source;
            }}>Login</span>
          </div>
        </div>
        :<div >
          <div className="text-center">
            <img src={SuccessIcon} />
          </div>
          <div className="text-center"><h2>Email send</h2></div>
          <div className="text-center"><p>Check your registered mail id</p></div>
        </div>}
      </div>
    </div>
  );
}

export default ForgetForm;
