import React from 'react';
export const ErrorMsg = (props) => {
  let className = 'text-red';
  if (props.message) {
    if (props.validator && props.validator()) {
      className += ' hide';
    }
  } else {
    className += ' hide';
  }

  const errorMsg = props.message || '';

  return (
    <span className={className}> {errorMsg}</span>
  )
}