import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Input, Select, Modal  } from 'antd';
import moment from 'moment-timezone';
import {ErrorMsg                                                                                                                                                                                                                                                                                       } from "./ErrorMessage";
import LogoImg from "../assets/image/logoAlpide.svg";
import SuccessIcon from "../assets/image/successIcon.png";
import { Router, useLocation } from "react-router-dom";

function ResetPasswordForm(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const [email, setEmail] = useState('');
  const [invalidEmailClass, setInvalidEmailClass] = useState(null);
  const [validPassword, setValidPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [invalidPasswordClass, setInvalidPasswordClass] = useState(null);
  const [passwordMismatch, setPasswordMismatch] = useState(null);
  const [repeatPassword, setRepeatPassword] = useState(null);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [invalidRepeatPasswordClass, setInvalidRepeatPasswordClass] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sentDone, setSentDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState(true);
  const loction = useLocation();
  // Get the value of the 'code' parameter
  let source = urlParams.get("source");
  const getValidURL = (source, country) => {
    let url;
    if (window.location.hostname === 'localhost') {
      country = "Localhost";
    } else if (!country || process.env.REACT_APP_ROOT_DOMAIN === "alpidedev.com") {
      country = "Dev"
    }
    let countryURIList = JSON.parse(process.env.REACT_APP_DATA_CENTER_BASE_URI || "{}");
    switch (source) {
      case 'erpApp': {
        url = countryURIList[country] ? countryURIList[country] : '';
        break;
      }
      default: {
        url = countryURIList[country];
      }
    }
    return url;
  }
 
  // useEffect(() => {

  //   let redirectUri = getValidURL(source);
  //   //window.location = redirectUri;
  // }, [source]);
  const token = urlParams.get('t');
  // const encodedToken = btoa(token)
 // console.log("helloDude",JSON.parse(atob(token)),getValidURL("erpApp",JSON.parse(atob(token))?.country))
  let repeatErrorMessage = <ErrorMsg
    message={<div className="errorMsg">Password does not match</div>}
    validator={() => {
      return !submittedOnce || (repeatPassword && !passwordMismatch);
    }}
  />

  const validateEmail = (val) => {
    return !!(val && val.toLowerCase().match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/))
  }
  if (!repeatPassword) {
    repeatErrorMessage = <ErrorMsg
      message={<div className="errorMsg">Confirm password is required</div>}
      validator={() => {
        return !submittedOnce || !!repeatPassword;
      }}
    />
  }

  function validatePasswordCheck(password) {
    const minLength = 8;
    const maxLength = 16;
    const specialCharacters = /[!@#$%^&*()\-_=+[\]{};:'",.<>/?\\|]/;
    const capitalLetter = /[A-Z]/;
    const number = /[0-9]/;
    const passwordc = password || '';

    if (passwordc.length < minLength || passwordc.length > maxLength) {
      return false;
    }
    if (!specialCharacters.test(password)) {
      return false;
    }
    if (!capitalLetter.test(password)) {
      return false;
    }
    if (!number.test(password)) {
      return false;
    }

    return true;
  }

  const handleForgotPassword = async () => {
    if(isLoading){return;}
    //console.log("helloDude",JSON.parse(atob(token)),getValidURL("erpApp",JSON.parse(atob(token))?.country))
    
    const encodedToken = token ? atob(token) : '';
    let tokenObj = token ? JSON.parse(encodedToken): {};
    let redirectUri = getValidURL(source, tokenObj.country || "United States (USA)");
    let payload = {
      "token" : token,
      "newPassword" : password
  }
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body:  JSON.stringify(payload)
      }
      const response = await fetch(`${redirectUri}/alpide/clientRelationship/resetPassword`, config)
     
      if (response.ok) {
        setSentDone(true)
        setIsLoading(false);
        const responseData = await response.json();
     
        return response
      } else {
        setErrorMessage(true)
        setIsLoading(false);
      }
    } catch (error) {
      
      setIsLoading(false);
    }
   
      if (!submittedOnce) {
        setSubmittedOnce(true);
      }


      if ( password && repeatPassword && password === repeatPassword && validPassword) {
     

      } else {
       
        setPassword(password);
        setRepeatPassword(repeatPassword);
        setInvalidPasswordClass(!password || !validPassword ? 'input-text-error' : '');
        setInvalidRepeatPasswordClass((!repeatPassword || !passwordMismatch) ? 'input-text-error' : '');
        setPasswordMismatch(password !== repeatPassword);
        setInvalidEmailClass(validateEmail(email) ? '' : 'input-text-error');

      }
  }

  return (
    <div className="forgetPasswordPart">
      <div className="forgetPasswordForm">
        {!sentDone ?
        <div >
          <div><img src={LogoImg} /></div>
          <div><h2>Reset your password</h2></div>
            <Form>
              <div className="inputField">
                <Input placeholder="Password" value={password} type='password' 
                  className={invalidPasswordClass || 'signup-form-input' }
                  onChange={(e) => {
                                    setPassword(e.target.value);
                                    setInvalidPasswordClass(!e.target.value ? 'input-text-error' : '');
                                    setPasswordMismatch(repeatPassword !== e.target.value);
                                    setValidPassword(validatePasswordCheck(e.target.value));
                                    // updateState({
                                    //     password: e.target.value,
                                    //     invalidPasswordClass: !e.target.value ? 'input-text-error' : '',
                                    //     passwordMismatch: repeatPassword !== e.target.value,
                                    //     validPassword: validatePasswordCheck(e.target.value),
                                    // });
                                  }}
                                  validator={() => {
                                    return !!password || validPassword;
                }}
                                />
                <ErrorMsg
                  validator={() => {
                    return !submittedOnce || (!!password && validPassword);
                  }}
                  message={<div className="errorMsg">Password required</div>}
                />
              </div>

              <div className="inputField">
                <Input placeholder="Repeat Password"
                                  value={repeatPassword}
                                  type='password'
                                  className={invalidRepeatPasswordClass || 'signup-form-input'}
                                  validator={() => {
                                    return !!repeatPassword;
                                  }}
                                  onChange={(e) => {
                                    setRepeatPassword(e.target.value);
                                    setInvalidRepeatPasswordClass(!e.target.value || (password !== e.target.value) ? 'input-text-error' : '');
                                    setPasswordMismatch(password !== e.target.value);
                                    // updateState({
                                    //     repeatPassword: e.target.value,
                                    //     invalidRepeatPasswordClass: !e.target.value || (password !== e.target.value) ? 'input-text-error' : '',
                                    //     passwordMismatch: password !== e.target.value,
                                    // });
                                  }}
                                />
                                {
                                  repeatErrorMessage
                                }
              </div>

              <div className="inputField">
                <Button className="signup-create-account"  onClick={() => {
                            handleForgotPassword(props);
                          }}>
                    {!isLoading ? <span>Submit</span>
                    :
                      <span><i class="fa fa-circle-o-notch fa-spin"></i>Loading</span>
                    }
                </Button>
              </div>
            </Form>
            <div className="inputField text-center">
              Back to <span className="cursor-pointer link" onClick={() => {
                   window.location = window.location.origin+"/?source="+source;
                  //window.location.href=('https://login.alpide.com/wp-login.php') 
                  }}>
                    <span>Login</span>
                </span>
              {/* <a href="https://login.alpide.com/wp-login.php">Login</a> */}
            </div>
        </div>
        :
        <div>
          <div className="text-center">
            <img src={SuccessIcon} />
          </div>
          <div className="text-center"><h2>Password Changed!</h2></div>
          <div className="text-center"><p>Your password has been changed successfully</p></div>
          <div className="inputField">
                <Button className="signup-create-account" onClick={() => {
                   window.location = window.location.origin+"/?source="+source;
                  //window.location.href=('https://login.alpide.com/wp-login.php') 
                  }}>
                    <span>Back to Login</span>
                </Button>
              </div>
        </div>
}
      </div>
    </div>
  );
}

export default ResetPasswordForm;
