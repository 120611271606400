import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/stylesheets/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { render } from 'react-dom';
import { CookiesProvider } from 'react-cookie';

//const root = ReactDOM.createRoot(document.getElementById('root'));
const app = document.getElementById('root');
//root.render(<App />);
render(
  <CookiesProvider>
    <App />
  </CookiesProvider>
  , app
);
// root.render(
//    <React.StrictMode>
//      <App />
//    </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
