import React, { useEffect } from "react";

function LoginForm() {
  const urlParams = new URLSearchParams(window.location.search);

// Get the value of the 'code' parameter
  let source = urlParams.get("source");
  
  useEffect(() => {
    window.location = `${process.env.REACT_APP_AUTH_REDIRECT_URL}?response_type=${process.env.REACT_APP_RESPONSE_TYPE}&client_id=${process.env.REACT_APP_CLIENT_ID}&state=test&scope=${process.env.REACT_APP_SCOPE}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}?source=${source}`;
   // let redirectUri = encodeURIComponent(`${process.env.REACT_APP_REDIRECT_URI}?source=${source}`);
    //console.log(`${process.env.REACT_APP_AUTH_REDIRECT_URL}?response_type=${process.env.REACT_APP_RESPONSE_TYPE}&client_id=${process.env.REACT_APP_CLIENT_ID}&state=test&scope=${process.env.REACT_APP_SCOPE}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}?source=${source}`);
   // window.location = `${process.env.REACT_APP_AUTH_REDIRECT_URL}?response_type=${process.env.REACT_APP_RESPONSE_TYPE}&client_id=${process.env.REACT_APP_CLIENT_ID}&state=test&scope=${process.env.REACT_APP_SCOPE}&redirect_uri=`+redirectUri;
 
  }, [source]);
  return (
    <div style={{
      height: "100vh",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    }}>
      
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  );
}

export default LoginForm;
